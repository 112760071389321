@import './../../styles/variables';

.clients {
  &_header {
    display: flex;
    justify-content: space-between;
    &_filters {
      display: flex;
      align-items: flex-end;
      &_filterby {
        width: 200px;
      }
      &_text {
        margin-left: 20px;
      }
      &_switch {
        margin-left: 20px !important;
      }
      &-fab {
        margin-left: 10px;
        height: 100%;
        svg:hover {
          cursor: pointer;
        }
      }
    }
  }
  &_actions {
    min-width: max-content;
    whitespace: 'nowrap';
  }
}

.activeicon {
  color: $color-medium;
  width: 25px !important;
  height: 25px !important;

  &.active {
    color: $color-primary;
  }
}
