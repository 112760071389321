@import '../../../styles/variables';

.billingForm {
  &_header {
    &-radios {
      display: flex !important;
      flex-flow: row !important;
    }
  }
  &_same {
    padding: 0 16px 12px 16px;
    &_checkbox {
      padding: 5px !important;
    }
  }
  &_content {
    max-width: 600px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 5px;
    row-gap: 20px;
    #MUI_billing_name {
      grid-column: 1 / 5;
      grid-row: 1 / 2;
    }
    #MUI_billing_rfc {
      grid-column: 5 / 7;
      grid-row: 1 / 2;
    }
    #MUI_state {
      grid-column: 1 / 4;
      grid-row: 2 / 3;
    }
    #MUI_city {
      grid-column: 4 / 7;
      grid-row: 2 / 3;
    }
    #MUI_billing_street {
      grid-column: 1 / 7;
      grid-row: 3 / 4;
    }
    #MUI_billing_cp {
      grid-column: 1 / 3;
      grid-row: 4 / 5;
    }
    #MUI_billing_email {
      grid-column: 3 / 7;
      grid-row: 4 / 5;
    }
    #MUI_billing_payment_type {
      grid-column: 1 / 4;
      grid-row: 5 / 6;
    }
    #MUI_billing_cfdi {
      grid-column: 4 / 7;
      grid-row: 5 / 6;
    }
  }
}
