@import './../../styles/variables';

.agreementsprices {
  &_header {
    display: flex;
    align-items: flex-end;
    &_filterby {
      border: 1px solid red;
      margin-right: 20px;
    }
  }
}
