@import './../../styles/variables';

.billingsupport {
  &_checkbox {
    border-radius: 20px;
  }

  &_chips {
    background-color: $color-primary;
  }

  .chips_manifest .MuiChip-clickable:focus {
    cursor: pointer;
  }

  .chips_manifest {
    background-color: $color-primary !important;
  }

  .MuiCheckbox-root {
    color: rgba(52, 202, 61, 0.54);
    border-radius: 10px !important;
  }
  &_totalRange {
    margin-right: 4px !important;
  }
}
