@import '../../../styles/variables';

.pickupForm {
  &_content {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-column-gap: 10px;
    //padding: 10px;

    div:nth-child(1) {
      grid-column: 1 / 2;

      label + .MuiInput-formControl {
        margin-top: 13px;
      }
    }
    div:nth-child(2) {
      grid-column: 2 / 3;
    }
    div:nth-child(3) {
      grid-column: 3 / 4;
    }
  }
}
