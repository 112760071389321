@import './../../styles/variables';

.pagetitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  height: 40px;
  color: $color-secondary;
  font-size: 20px;
  padding: 0 10px;

  &_link {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &_back-icon {
    color: $color-normal !important;
    font-size: 30px !important;
    padding: 0;
  }

  &_chip {
    margin-left: 10px;
    font-size: 14px;
    background-color: #ee8946;
    padding: 5px 12px;
    border-radius: 8px;
    color: white;
    text-transform: capitalize;
  }
}

