@import '../../../styles/variables';

.clientForm {
  &_header {
    &-search {
      display: flex;
      flex-direction: row !important;
      div {
        margin-top: 2px;
        transition: left;
        transition-delay: 2000ms;
      }
      button {
        margin-left: 10px;
      }
    }
  }

  &_content {
    max-width: 600px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;

    :nth-child(1) {
      grid-column: 1 / 5;
      grid-row: 1 / 2;
    }

    :nth-child(2) {
      grid-column: 5 / 7;
      grid-row: 1 / 2;
    }

    :nth-child(3) {
      grid-column: 1 / 7;
      grid-row: 2 / 3;
    }

    :nth-child(4) {
      grid-column: 1 / 5;
      grid-row: 3 / 4;
    }

    :nth-child(5) {
      grid-column: 5 / 7;
      grid-row: 3 / 4;
    }
  }
}
