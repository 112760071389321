@import '../../../styles/variables';

.city-edit-modal {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 3rem 2rem;

  gap: 1rem;
  height: max-content;
  width: 90%;

  &-switch {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
}
