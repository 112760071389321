.shipmentTypesForm {
  // height: max-content;

  &_content {
    padding: 0 !important;
    &_radiogroup {
      max-width: 550px;
      display: flex;
      flex-direction: row !important;
      justify-content: space-evenly;

      &_radio {
        max-width: 120px;
        height: 60px;

        &_label {
          // display: block;
          width: 100px;
          > b {
            // border: 1px solid blue;
          }
        }
      }
    }
  }
}
