@import '../../../../../styles/variables';

.GoodsModal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  #MUI_description {
    grid-column: 3 / 5;
  }
  #MUI_active {
    grid-column: 1/3;
  }
}
