@import './../../styles/variables';

.request {
  width: 600px;
  &_header {
    display: flex;
    align-items: center;
  }
  .requestCard {
    margin-bottom: 15px;
  }
}
