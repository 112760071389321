@import './../../styles/variables';

.routes {
  &_header {
    display: flex;
    justify-content: space-between;
    //align-items: flex-end;
    //justify-content: flex-start;


    &_filters {

      display: flex;
      align-items: flex-end;

      &_filterby {
        width: 200px;
      }

      &_switch {
        font-size: 12px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        p {
          margin-right: 20px;
        }
      }
    }
  }
}


.routes-city-wrapper {
  text-align: left;
}
.routes-city {
  font-size: 15px;
}
.routes-state {
  font-size: 9px;
}
