@import './../../styles/variables';

.menu-container {
  flex-shrink: 1;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $header-height;
  min-height: $header-height;
  color: white;
  margin-left: 14px;
}

.menu-label {
  padding: 0 8px;
}

.menu-list {
  padding: 0 !important;
}

.menu-item {
  height: 50px;
  margin-top: 1px;
  cursor: pointer;
  a {
    text-decoration: none;
  }
  &.subitem {
    height: 40px;
  }
  &.selected {
    background-color: $color-primary;
  }
}

.menu-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  width: 200px !important;
  height: 100%;
  padding: 5px 0 5px 5px;
  &.subitem {
    margin-left: 10px;
  }
}

.menu-list-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-list-item-icon {
  color: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px !important;
  &.subitem {
    background-color: transparent;
    font-size: 20px !important;
  }
  &.selected {
    color: white;
  }
}

.menu-list-item-text {
  color: $color-light;
  font-size: 13px;
  white-space: nowrap;
  margin-left: 5px;
  &.subitem {
    font-size: 13px;
  }
}

.menu-list-item-expand-icon {
  background: transparent;
  height: 30px !important;
  width: 40px !important;
  min-width: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.menu-option-icon {
  color: $color-light;
  font-size: 20px !important;
}

.menu-suboption-icon {
  color: $color-light;
  font-size: 10px !important;
}

.menu-divider {
  border-bottom: 1px solid $color-normal;
  // margin: 0 10px;
}

button.button-toggle {
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  outline: transparent;
  cursor: pointer;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
