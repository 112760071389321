@import "../../styles/variables";
.manifests {

  &_restore {
    background-color: $color-complementary;
  }

  &_download {
    top: -50px;
    left: 10px;
    position: relative;
    height: 2rem !important;
    width: 2rem !important;
    color: $color-complementary-3;
    :hover {
      cursor: pointer;
    }
  }

  &_billDocs{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    padding: 10px;
  }
  

}