@import './../../styles/variables';

.agreements {
  _header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filterby {
      width: 200px;
    }
  }
  &_activeicon {
    color: $color-medium;
    width: 25px !important;
    height: 25px !important;
    &-active {
      color: $color-primary;
    }
  }
}
