@import './../../styles/variables';

.coverage {
  &_header {
    display: flex;
    justify-content: space-between;
    //align-items: flex-end;
    //justify-content: flex-start;


    &_filters {

      display: flex;
      align-items: flex-end;

      &_filterby {
        width: 200px;
      }

      &_text {
        margin-left: 20px;
      }

      &_switch {

        margin-left: 20px !important;
      }

      &-fab {
        margin-left: 10px;
        height: 100%;

        svg:hover {
          cursor: pointer;
        }

      }

    }
  }

  .chips_manifest {
    background-color: $color-primary !important;
  }

}
