@import './../../styles/variables';

.extra-costs-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.extra-costs-tabs {
  width: 200px;
}
