.conditions {
  &-content {
    &-row {
      font-size: 12px;
      height: 65px;
      display: flex;
      flex-direction: row;
      place-content: flex-start;

      p {
        width: 120px;
      }

      &-radio {
        display: flex;
        width: fit-content;
        flex-direction: row !important;
        justify-content: flex-start;
      }

      .input {
        width: 200px;
        min-width: 150px;
      }

      &-maneuver {
        display: flex;
        flex-direction: row !important;
        place-content: space-between;

        &-input {
          width: 120px;
        }

        #MUI_container_id {
          margin-right: 10px;
          min-width: 120px !important;
        }
      }
    }
  }
}
