@import './../../styles/variables';

.providers {
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    &_filters {
      width: 800px;
      &_filterby {
        width: 200px;
        margin-right: 20px;
      }
    }
  }
  &_check-cell {
    &_icon {
      color: $color-primary;
      font-size: 30px !important;
    }
  }
}
