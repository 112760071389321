@import '../../styles/variables';

.contactsearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  &_field {
    width: 160px;
  }
  &_save {
    color: $color-primary !important;
  }
}
