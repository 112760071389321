@import '../../../styles/variables';

.route-modal {
  padding: 10px;
  width: 200px;
  min-width: 500px;
}

.route-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.route-modal-label {
  width: 120px;
}
