@import '../../../styles/variables';

.quoteGeneral {
  display: flex;
  flex-direction: row;

  &_column-1 {
    width: 500px;
    min-width: 400px;
  }
  &_column-2 {
    width: 600px;
    min-width: 400px;
  }
  &_column-3 {
    width: 600px;
    min-width: 400px;
  }

  &_card {
    margin-bottom: 10px;
  }
}
