@import '../../../styles/variables';

.product_price_modal {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4rem repeat(auto-fill, 3rem);
  grid-gap: 10px;
  row-gap: 20px;
  padding: 0;
  margin: 10px 0 10px 0;
  width: 550px;

  &-meta {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2rem 2rem;

    h4, p {
      margin: 0;
    }
  }


  &-service {
    display: grid;
    grid-template-columns: 5rem repeat(4, 20%);
    grid-template-rows: 4rem;
    column-gap: 5px;

    p {
      padding: 0;
      align-self: start;
    }

    #MUI_cost {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    #MUI_minPrice {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }

    #MUI_pubPrice {
      grid-column: 4 / 5;
      grid-row: 1 / 2;
    }

    #MUI_weight {
      grid-column: 5 / 6;
      grid-row: 1 / 2;
    }

  }

}
