@import './../../../styles/variables';

.providers-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.providers-header-filterby {
  width: 200px;
}

.check-circle-icon {
  color: $color-primary;
}
