@import '../../../styles/variables';

.providers {
  &_details {
    &_content {
      font-size: 12px;

      &-head {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.5rem 1.5rem;
        grid-column-gap: 10px;

        &_location {
          &_title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        margin-bottom: 10px;
      }

      &-summary {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
      }

      &-labels {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 10px;
        text-align: left;
        align-items: center;
        font-weight: bold;
      }

      &-list {
        &-item {
          display: grid;
          grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
          grid-template-rows: 20px;
          grid-column-gap: 10px;
          margin-bottom: 15px;
          text-align: right;
          align-items: center;
          &-input {
            width: 60%;
          }
          div:nth-child(1),
          :nth-child(3) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }
        }
      }
    }
  }

  button {
    justify-self: end;
    margin-right: 10px;
  }

  &_card {
    margin-bottom: 20px;
    &_header {
      button {
        justify-self: end;
        margin-right: 10px;

        svg {
          height: 2rem;
          width: 2rem;
          margin-left: 0;
        }
      }
    }

    &-checkbox {
      border-radius: 20px;
    }

    .MuiCheckbox-root {
      color: rgba(52, 202, 61, 0.54);
      border-radius: 10px !important;
    }

    svg {
      height: 2rem;
      width: 2rem;
      margin-left: 15px;
    }

    &_msprovider {
      display: grid;
      grid-template-columns: 10% repeat(3, 25%) 10%;
      grid-column-gap: 10px;

      #checkbox {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        place-self: center;
        //margin-top: 4.5rem;
        svg {
          margin: 0;
        }
      }

      &-actions {
        button {
          width: 32px;
          height: 32px;

          svg {
            margin: 0;
          }
        }
      }

      &-collapse {
        &-content {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: auto auto auto;

          margin: 10px;
          border-radius: 10px;
          border: 1px solid $color-medium;

          p,
          h5 {
            margin: 0;
          }

          &-head {
            display: grid;
            grid-template-columns: 70% 30%;
            grid-template-rows: repeat(3, auto);
            margin-bottom: 10px;

            p:nth-child(1) {
              grid-column: 1 / 2;
              grid-row: 1 / 2;
            }

            p:nth-child(2) {
              grid-column: 1 / 2;
              grid-row: 2 / 3;
            }

            p:nth-child(3) {
              grid-column: 1 / 2;
              grid-row: 3 / 4;
            }
          }

          &-pallets {
            grid-column: 1 / 2;
            grid-row: 2 / 3;

            display: grid;
            grid-template-columns: repeat(3, 33.3%);
            grid-template-rows: 3rem auto;
            place-items: center;

            border-radius: 10px;
            border: 1px solid $color-medium;

            margin-bottom: 10px;
            padding: 0 5px 0 5px;

            p {
              width: 100%;
              margin: 0;
              text-align: left;
            }

            p:nth-child(1) {
              grid-row: 1 / 2;
              grid-column: 1 / 4;
            }

            //p:nth-child(2) {
            //  grid-row: 2 / 3;
            //  //grid-column: 1 / 4;
            //}
          }

          &-product {
            display: grid;
            grid-template-columns: 60% 40%;
            grid-template-rows: repeat(auto-fill, 1.7rem);

            border: 1px solid $color-medium;
            border-radius: 10px;
            padding: 10px;

            margin-bottom: 20px;

            h5 {
              grid-column: 1 / 2;
              grid-row: 1 / 2;
            }

            p:nth-child(2) {
              grid-column: 2 / 3;
              grid-row: 3 / 4;
            }

            p:nth-child(3) {
              grid-column: 1 / 2;
              grid-row: 3 / 4;
            }

            p:nth-child(4) {
              grid-column: 1 / 2;
              grid-row: 2 / 3;
            }
          }
        }
      }
    }

    &_content {
      display: grid;
      grid-template-columns: 10% repeat(3, 25%) 10%;
      grid-template-rows: 3rem 3rem;

      grid-row-gap: 10px;
      grid-column-gap: 10px;

      #checkbox {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        place-self: center;
        margin-top: 4.5rem;

        svg {
          margin: 0;
        }
      }

      #MUI_client {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }

      div:nth-child(3) {
        grid-column: 3 / 5;
        grid-row: 1 / 2;
      }

      div:nth-child(4) {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }

      div:nth-child(5) {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
      }

      div:nth-child(6) {
        grid-column: 4 / 5;
        grid-row: 2 / 3;
      }

      #deleteButton {
        grid-column: 5 / 6;
        place-self: center;
        margin-top: 4.5rem;

        svg {
          margin: 0;
        }
      }

      //&-input {
      //  width: 100px;
      //  margin-left: 15px !important;
      //}
    }
  }
}
