@import "../../styles/variables";

.authorizations {

  &_header {
    display: flex;
    justify-content: space-between;
    //align-items: flex-end;
    //justify-content: flex-start;


    &_filters {

      display: flex;
      align-items: flex-end;

      &_filterby {
        width: 200px;
      }

      &_switch {
        font-size: 12px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        p {
          margin-right: 20px;
        }
      }

      &-fab {
        margin-left: 30px;
        height: 100%;

        svg:hover {
          cursor: pointer;
        }

      }

    }
  }

  #disabled {
    svg {
      background-color: $color-medium;
    }
  }
}