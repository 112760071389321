@import './../../styles/variables';

.quotes {
  &_header {
    display: flex;
    justify-content: space-between;

    &_filters {
      display: flex;
      align-items: flex-end;

      &_filterby {
        width: 200px;
      }

      &_switch {
        font-size: 12px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        p {
          margin: 0 20px 0 20px;
        }
      }
    }
  }
  .actionMenu_button {
    //border-radius: 5px !important;
    place-content: flex-end !important;

    height: 30px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;

    //.actionMenu_button_main {
    //  //border-radius: 5px !important;
    //  //width: max-content;
    //  height: 30px !important;
    //  border-top-left-radius: 5px !important;
    //  border-bottom-left-radius: 5px !important;
    //}
  }
}
