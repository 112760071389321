.dedicatedDetail {
  max-width: 600px;
  display: grid;
  gap: 20px;
  justify-content: start;
  grid-template-columns: repeat(5, 1fr);

  #deleteButton {
    grid-column: 5 / 6;
    grid-row: 1 / 2;

    justify-self: end;
  }

  #MUI_unit_id {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }

  #radios {
    grid-column: 3 / 6;
    grid-row: 2 / 3;
    justify-self: end;
  }

  #MUI_content {
    grid-column: 1 / 6;
    grid-row: 3 / 4;
  }

  #MUI_comments {
    grid-column: 1 / 6;
    grid-row: 4 / 5;
  }

  &_radios {
    display: flex;
    flex-direction: row !important;
  }
}
