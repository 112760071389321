.packagingDetail {
  max-width: 600px;
  display: grid;
  column-gap: 20px;
  row-gap: 25px;
  justify-content: start;
  grid-template-columns: repeat(4, 22%);

  #MUI_package {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  #MUI_product {
    grid-column: 3 / 5;
    grid-row: 1 / 2;
  }

  #MUI_quantity {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  #MUI_content {
    grid-column: 2 / 5;
    grid-row: 2 / 3;
  }

  #MUI_long {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  #MUI_width {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  #MUI_height {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
  }

  #MUI_weight {
    grid-column: 4 / 5;
    grid-row: 3 / 4;
  }

  #MUI_volumetric {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }

  #MUI_comments {
    grid-column: 2 / 5;
    grid-row: 4 / 5;
  }

  &_stackable {
    grid-column: 1 / 3;
    grid-row: 5 / 6;

    display: grid !important;
    grid-template-columns:  40% 60%;
    align-content: end;
    justify-items: end;

    p {
      grid-column: 1 / 2;
      align-self: center;
    }

    &_radios {

      display: flex;
      flex-direction: row !important;
    }
  }

  &_fragile {
    grid-column: 3 / 5;
    grid-row: 5 / 6;

    display: grid !important;
    grid-template-columns:  40% 60%;
    align-content: end;
    justify-items: end;

    p {
      grid-column: 1 / 2;
      align-self: center;
    }

    &_radios {

      display: flex;
      flex-direction: row !important;
    }
  }


}
