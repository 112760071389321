@import './../../../styles/variables';

.order-load-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.orders-modal-container {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.orders-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid $color-disabled;
}

.order-load-modal-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 2rem;
  margin-top: 2rem;
  font-weight: 600;
  font-size: 10px;
}

.order-modal-fields {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 10px;
  &.spaced {
    margin-top: 20px;
  }
}

.order-modal-file-field {
  width: 300px;
}

.order-modal-date-field {
  margin-top: 18px;
}

.order-modal-icon-file {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
