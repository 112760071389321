
.messagingDetail {
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  max-width: 600px;
  gap: 20px;
  justify-content: start;

  &_delete {
    grid-column: 5 / 6;
    grid-row: 1 / 2;
  }

  #MUI_quantity {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  #MUI_content {
    grid-column: 2 / 6;
    grid-row: 2 / 3;
  }

  #MUI_weight {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  #MUI_comments {
    grid-column: 2 / 6;
    grid-row: 3 / 4;
  }
}
