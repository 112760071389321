@import '../../../styles/variables';

.locationSelect {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
  #MUI_state {
    grid-column: 2 / 3;
  }
  #MUI_neighborhood {
    grid-column: 1 / 3;
  }
  #MUI_street {
    grid-column: 3 / 5;
  }
  #MUI_reference_streets {
    grid-column: 3 / 5;
  }
  #MUI_address {
    grid-column: 1 / 5;
  }
}
