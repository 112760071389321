.packages {
  display: grid;
  grid-template-columns: 1fr;
  //grid-template-rows: 1fr;

  grid-template-rows: auto;
  row-gap: 20px;

  &_list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2rem auto;
    //height: 500px;
    overflow: auto;
    row-gap: 10px;

    p {
      margin: 5px;
      text-align: right;
    }
  }
}
