@import './../../styles/variables';

.businessBilling {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filterby {
      width: 200px;
    }
  }
}
