@import '../../../styles/variables';

.quoteCosts {
  display: flex;
  flex-direction: row;

  &_left {
    min-width: 600px;
    margin-right: 10px;

    &_incidences {
      margin-top: 10px;

      &_content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
          display: flex;
          flex-direction: row;

          svg {
            height: 1.5rem !important;
            width: 1.5rem !important;
          }

          p {
            margin-right: 30px;
            font-size: 13pt;
          }
        }
      }
    }

    &_summary {
      margin-top: 10px;
    }

  }

  &_right {
    min-width: 500px;
    margin-right: 10px;

    &_payment {
      width: 500px;
      height: auto;
      margin-top: 10px;

      &_content {
        display: grid !important;
        grid-template-columns: 50% 50%;

        #payment_type {
          grid-column: 1 / 3;
          grid-row: 1 / 2;
        }

        &-switch {
          grid-column: 2 / 3;
          grid-row: 1 / 2;

          place-content: center;
        }

        &-request {
          grid-column: 1 / 3;
          grid-row: 2 / 3;

          display: grid;
          grid-template-columns: 20% 80%;
          grid-template-rows: 3rem  4rem;
          column-gap: 10px;

          margin-top: 2rem;

          div {
            grid-column: 1 / 2;
            grid-row: 1 / 2;

            align-self: baseline;
            color: $color-complementary-4;

            svg {
              width: 100%;
              height: 85%;
            }
          }

          #exceed {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
          }

          button {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
            width: fit-content;
            align-self: center;
          }
        }

        &-credit {
          display: grid !important;
          grid-template-columns: 20% 80%;

          margin-top: 20px;
          padding-right: 10px;

          svg {
            grid-column: 1 / 2;
            align-self: center;
            color: $color-complementary-3;
            width: 80%;
            height: 81%;
          }

          p {
            grid-column: 2 / 3;
            align-self: center;
          }
        }

      }
    }

  }


}
