@import '../../../styles/variables';

.destinationForm {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(auto-fill, 3rem);
  max-width: 600px;
  grid-row-gap: 5px;
  grid-column-gap: 10px;

  #MUI_destination-recipient {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  #MUI_destination-contact {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  #MUI_destination-phone {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  #MUI_destination-email {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }

  &_location {
    display: grid;
    grid-template-columns: 47% 50%;
    grid-column-gap: 10px;
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    #MUI_destination-country {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    #MUI_destination-cp {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }

  #MUI_destination-state {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  #MUI_destination-municipality {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  #MUI_destination-city {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }

  #MUI_destination-street {
    grid-column: 2 / 3;
    grid-row: 5 / 6;
  }

  #MUI_destination-suburb {
    grid-column: 2 / 3;
    grid-row: 6 / 7;
  }
  #MUI_destination-number {
    grid-column: 2 / 3;
    grid-row: 7 / 8;
  }
  #MUI_destination-external_number {
    grid-column: 2 / 3;
    grid-row: 9 / 10;
  }

  #MUI_destination-references {
    grid-column: 2 / 3;
    grid-row: 11 / 12;
  }
}
