.providersmodal {
  &_fields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    #MUI_provider_number {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    #MUI_name {
      grid-row: 1 / 2;
      grid-column: 2 / 4;
    }
    #MUI_rfc {
      grid-row: 1 / 2;
      grid-column: 4 / 5;
    }
    #MUI_contact_name {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
    }
    #MUI_email {
      grid-row: 2 / 3;
      grid-column: 3 / 5;
    }
    #MUI_phone {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
    }
    #MUI_address {
      grid-row: 3 / 4;
      grid-column: 2 / 5;
    }
    #MUI_profit_percentage {
      grid-row: 4 / 5;
      grid-column: 1 / 2;
    }
  }

  &_shipmenttypes {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 10px;
    row-gap: 10px;
    padding-top: 20px;
  }
}
