@import '../../../styles/variables';

.contactForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 600px;
  grid-column-gap: 20px;
  padding: 0 10px;

  &_user {
    display: flex;
    width: 100%;
    flex-direction: column;
    grid-row-gap: 10px;
  }

  &_location {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;

    #locationSelect {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
      #MUI_postal_code {
        max-width: 120px;
        grid-column: 1 / 2;
      }
      #MUI_state {
        grid-column: 1 / 2;
      }
      #MUI_neighborhood {
        grid-column: 1 / 2;
      }
      #MUI_street {
        grid-column: 1 / 2;
      }
      #MUI_reference_streets {
        grid-column: 1 / 2;
      }
      #MUI_address {
        grid-column: 1 / 2;
      }
    }
  }

  #MUI_origin-remitter {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  #MUI_origin-contact {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  #MUI_origin-phone {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  &.compact {
    grid-template-columns: 1fr;

    #MUI_origin-country {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    #statecity {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    #MUI_state_id {
      width: 100%;
      margin-bottom: 15px;
    }
    #MUI_city_id {
      width: 100%;
    }
    #MUI_origin-street {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
    #MUI_origin-cp {
      grid-column: 1 / 2;
      grid-row: 4 / 5;
    }
    #MUI_origin-name {
      grid-column: 1 / 2;
      grid-row: 5 / 6;
    }
    #MUI_origin-references {
      grid-column: 1 / 2;
      grid-row: 6 / 7;
    }
  }
}
