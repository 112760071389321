@import 'variables';

@font-face {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  src: local('OpenSans-Regular'),
    url('../assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  src: local('OpenSans-Bold'),
    url('../assets/fonts/OpenSans-Bold.ttf') format('truetype');
}

html {
  font-size: 16px;
  font-family: 'OpenSans-Regular';
}

body {
  background-color: $color-light;
  height: 100vh;
  margin: 0;
}

// Estilos personalizados globales
// usar prefijo g-

.g-page-header {
  padding: 10px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.g-row-action-button {
  background: $color-primary;
  color: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  margin: 5px;

  &.delete {
    background: $color-delete;
  }

  &.disabled {
    background: $color-disabled;
    cursor: auto;
  }

  &.complementary {
    background: $color-complementary;
  }

  &.complementary-2 {
    background: $color-complementary-2;
  }

  &.complementary-3 {
    background: $color-complementary-3;
  }

  &.complementary-4 {
    background: $color-complementary-4;
  }

  &.complementary-5 {
    background: $color-complementary-5;
  }
}

.g-toast {
  background-color: $color-complementary !important;
  border-radius: 10px !important;
  padding: 15px !important;
  border: 2px solid white;
}

.g-toast-body {
  color: $color-dark !important;
}

.g-error-toast {
  background-color: $color-delete !important;
  border-radius: 10px !important;
  padding: 15px !important;
  border: 2px solid white;
  color: white !important;
}

.g-activeicon {
  color: $color-medium;
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
  &.active {
    color: $color-check;
  }
}
