.agreementmodal {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 3fr 3fr 2fr 1fr;
  grid-column-gap: 10px;
  #MUI_client {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  #MUI_monthly_billing {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  #MUI_end_date_agreement {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }

  #MUI_active {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    margin: 0 10px;
  }
}
