@import './../../styles/variables';

.requests {
  &_header {
    display: flex;
    justify-content: space-between;
    //align-items: flex-end;
    //justify-content: flex-start;


    &_filters {

      display: flex;
      align-items: flex-end;

      &_filterby {
        width: 200px;
      }

      &_switch {
        font-size: 12px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        p {
          margin-right: 20px;
        }
      }

      &-fab {
        margin-left: 10px;
        height: 100%;

        svg:hover {
          cursor: pointer;
        }

      }

    }
  }

  //.actionMenu_button {
  //  border-radius: 5px !important;
  //  place-content: flex-end !important;
  //
  //  .actionMenu_button_main {
  //    border-radius: 5px !important;
  //    width: max-content;
  //  }
  //}
}

