@import '../../../styles/variables';

.costBreakdown {
  width: 500px;
  min-width: 500px;
  max-height: 600px;
  display: flex;
  flex-direction: column;

  &_header {
    &_actions {

      div:nth-child(2) {
        display: grid;
        grid-template-columns: 25% auto;
        grid-column-gap: 16px;
      }

      &-switch {
        grid-column: 2 / 3;
        grid-row: 1 / 2;

        place-content: center;
      }

    }
  }

  &_content {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr;

    &_fields {

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;

      span {
        width: 150px;
      }

      &-discount {
        grid-column: 2 / 3 !important;
      }


      &_item {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        height: 40px;

        &-other {
          grid-column: 1 / 3;
          display: grid;
          grid-template-columns: 10% 45% 45%;
          grid-template-rows: 1rem 3rem;
          column-gap: 5px;

          span {
            grid-column: 1 / 2;
            grid-row: 1 / 3;

            align-self: end;
            font-size: 14px;
          }

          div:nth-child(2) {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            margin-top: 1.25rem;
          }

          div:nth-child(3) {
            grid-column: 3 / 4;
            grid-row: 2 / 3;
          }
        }
      }
    }

    &_info {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;

      &-totals {
        grid-column: 2 / 3;

        display: grid;
        grid-template-columns: 100px 1fr;

        align-self: end;
        grid-gap: 20px;
        margin-top: 20px;
        font-size: 16px;

        p {
          margin: 0;
        }

        &-label {
          grid-column: 1 / 2;
        }

        &-value {
          grid-column: 2 / 3;
          text-align: end;
        }
      }

    }

  }
}
