@import './../../styles/variables';

$transition-speed: 0.2s;

.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.appbar {
  height: $header-height;
  max-height: $header-height;
  width: calc(100% - #{$sidemenu-open-width}) !important;
  margin-left: $sidemenu-open-width !important;
  transition-property: margin, width !important;
  transition-duration: $transition-speed !important;
  transition-timing-function: easing !important;
  border-bottom: none;
}

.appbar-drawer-close {
  width: calc(100% - #{$sidemenu-close-width}) !important;
  margin-left: $sidemenu-close-width !important;
  transition-property: margin, width !important;
  transition-duration: $transition-speed !important;
  transition-timing-function: ease-out !important;
}

.app-toolbar {
  background-color: $color-secondary;
  border: none;
  min-height: $header-height !important;
}

.drawer {
  background-color: $color-complementary-3;
  flex-shrink: 0;
  transition-property: width;
  transition-duration: $transition-speed;
  transition-timing-function: easing;
}

.drawer-close {
  transition-property: width;
  transition-duration: $transition-speed;
  transition-timing-function: ease-out;
}

.drawer-paper {
  background-color: $color-secondary !important;
  width: $sidemenu-open-width !important;
  transition-property: width !important;
  transition-duration: $transition-speed !important;
  transition-timing-function: ease-out !important;
}

.drawer-paper-close {
  width: $sidemenu-close-width !important;
  transition-property: width !important;
  transition-duration: $transition-speed !important;
  transition-timing-function: ease-out !important;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: $header-height;
  padding: 10px 20px;
  margin-left: $sidemenu-open-width;
  transition-property: margin;
  transition-duration: $transition-speed;
  transition-timing-function: easing;
}

.content-drawer-close {
  margin-left: $sidemenu-close-width;
  transition-property: margin;
  transition-duration: $transition-speed;
  transition-timing-function: ease-out;
}

.hide {
  display: 'none';
}
