@import './../../styles/variables';

.containertypes {
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    &_filters {
      &_filterby {
        width: 200px;
        margin-right: 20px;
      }
    }
  }
  &_check-cell {
    &_icon {
      color: $color-primary;
      font-size: 30px !important;
    }
  }
}

.activeicon {
  color: $color-medium;
  width: 25px !important;
  height: 25px !important;
  &.active {
    color: $color-primary;
  }
}
