@import '../../../styles/variables';

.dedicated-price-modal {
  padding: 10px;
  width: 500px;
}

.dedicated-price-modal-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 11px;
}
