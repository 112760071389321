@import '../../../styles/variables';

.requirements {
  &_content {
    .selected {
      background-color: #76b06e;
    }

    .normal {
      background-color: $color-light;
    }

    .MuiChip-clickable:focus {
      //background-color: $color-primary !important;
      cursor: pointer;
    }

    .MuiChip-clickable:hover {
      cursor: pointer;
      background-color: $color-primary-light !important;
    }

    &-other {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;

      margin-top: 20px;
      div {
        width: 90%;
      }

      button {
        margin: 15px 0 0 0;
        align-self: end;
        background-color: $color-primary;
      }

      &-disabled {
        background: $color-disabled;
      }
    }
  }
}
