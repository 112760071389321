@import '../../../styles/variables';

.delivery {
	max-width: 1000px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	&_left {
		&_incidences {
			margin-bottom: 10px;
			&-content {
				// padding-top: 0 !important;
				&_item {
					display: flex;
					flex-direction: row;
					align-items: center;
					&_label {
						font-size: 14px;
						width: 250px;
					}
				}
			}
		}

		&_date {
			&_content {
				display: grid;
				grid-template-columns: 1fr 1fr;
				#MUI_deliveryDate {
				}
			}
			margin-bottom: 10px;
		}

		&_info {
			margin-bottom: 10px;
			&_content {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				gap: 4px;
				#MUI_folio {
					width: 200px;
				}

				#MUI_date_accuse {
				}
			}
		}
	}

	&_right {
		margin-left: 10px;

		&_accuses {
			// min-height: 400px;

			&_dropzone {
				text-align: center;
				padding: 0 20px;
				height: 300px;
				border: 2px dotted #777;
				border-radius: 20px;
				display: grid;
				place-content: center;
				justify-self: center;
			}

			&_thumbnail {
				display: flex;
				flex-direction: row;
				margin-top: 10px;
				align-items: center;
				img {
					border: 1px solid $color-disabled;
					padding: 10px;
					width: auto;
					height: 120px;
				}
				&-save {
					margin-left: 10px;
					text-align: right;
				}
			}

			&_files {
				min-width: 400px;

				&-list {
					font-size: 9px;
					&-item {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr 1fr;
						margin: 10px;
						align-items: center;
						border: 1px solid $color-disabled;
						img {
							padding: 10px;
							width: auto;
							height: 80px;
						}
						&-img {
							grid-column: 1 / 2;
							grid-row: 1 / 2;
							place-self: center;
						}

						&-name {
							grid-column: 2 / 3;
							grid-row: 1 / 2;
							place-self: center;
							//margin-left: -50px;
						}

						&-fab {
							grid-column: 4 / 5;
							grid-row: 1 / 2;
							place-self: center;
						}
					}
				}
			}
		}
	}
}
