@import '../../../styles/variables';

.deliverytypeForm {
  height: 150px;

  &_content {
    display: flex;
    justify-content: center;

    &-radios {
      display: flex !important;
      flex-flow: row !important;

      #MUI_estimated {
        width: auto;
      }
    }
  }
}
