@import '../../../styles/variables';
.quotefiles {
  display: flex;
  flex-direction: row;

  &_pdfs {
    //margin: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, 6rem);

    //border: 1px solid red;
    height: 750px;
    width: 700px;
    min-width: 600px;
    margin: 10px;
    overflow: auto;

    background-color: $color-white !important;

    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    &_row {
      //border-bo: 1px solid $color-light;
      display: grid;
      grid-template-columns: 50% 40% 10%;
      margin: 10px;
      place-content: center;

      &-name {
        grid-column: 1 / 2;
        place-self: center;
        //margin-left: -50px;
      }

      &-fab {
        place-self: center;
      }
    }
  }

  &_files {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 18rem auto;

    grid-row-gap: 10px;

    width: 750px;
    min-width: 650px;
    margin: 10px;

    &_add {
      display: grid;
      grid-template-columns: 80% 20%;
      place-content: center;

      .page-title-container {
        grid-column: 1 / 2;
      }
    }

    &_dragarea {
      margin: 10px;
      height: 17rem;

      &-content {
        padding: 5px;
        display: grid;
        grid-template-columns: repeat(3, 33%);
        grid-template-rows: 8rem 5rem 3rem;

        &-dropzone {
          grid-column: 1 / 4;
          grid-row: 1 / 2;

          border: 1px dashed $color-dark;
          border-radius: 10px;

          p,
          h3 {
            text-align: center;
          }
        }

        &-chips {
          grid-column: 1 / 4;
          grid-row: 2 / 3;

          padding: 10px;
          width: 500px;

          &-chip {
            margin: 5px;
            background-color: $color-primary !important;
          }
        }

        button {
          border-radius: 5px;
        }

        &-savebutton {
          grid-column: 3 / 4;
          grid-row: 3 / 4;
          width: 150px;
          place-self: center;

          span {
            font-size: 11pt;
            text-shadow: #414243;
            //color: $color-dark;
          }
        }
      }
    }

    &_evidences {
      margin-top: 1rem;

      &-row {
        display: grid;
        grid-template-columns: 15% 35% 40% 10%;
        margin: 10px;
        place-content: center;

        &-img {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          place-self: center;
        }

        &-name {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          place-self: center;
          //margin-left: -50px;
        }

        &-fab {
          grid-column: 4 / 5;
          grid-row: 1 / 2;
          place-self: center;
        }
      }
    }
  }
}
