@import '../../../styles/variables';

.originForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 3rem);
  max-width: 600px;
  grid-column-gap: 10px;
  grid-row-gap: 5px;

  &_location {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    #MUI_origin-country {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    #MUI_origin-cp {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }

  #MUI_origin-state {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  #MUI_origin-municipality {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  #MUI_origin-city {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }

  #MUI_origin-street {
    grid-column: 2 / 3;
    grid-row: 5 / 6;
  }

  #MUI_origin-suburb {
    grid-column: 2 / 3;
    grid-row: 6 / 7;
  }
  #MUI_origin-number {
    grid-column: 2 / 3;
    grid-row: 7 / 8;
  }
  #MUI_origin-external_number {
    grid-column: 2 / 3;
    grid-row: 9 / 10;
  }

  #MUI_origin-references {
    grid-column: 2 / 3;
    grid-row: 11 / 12;
  }

  #MUI_origin-remitter {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  #MUI_origin-contact {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  #MUI_origin-phone {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  &.compact {
    grid-template-columns: 1fr;

    #MUI_origin-country {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    #statecity {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    #MUI_state_id {
      width: 100%;
      margin-bottom: 15px;
    }
    #MUI_city_id {
      width: 100%;
    }
    #MUI_origin-street {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
    #MUI_origin-cp {
      grid-column: 1 / 2;
      grid-row: 4 / 5;
    }
    #MUI_origin-name {
      grid-column: 1 / 2;
      grid-row: 5 / 6;
    }
    #MUI_origin-references {
      grid-column: 1 / 2;
      grid-row: 6 / 7;
    }
  }
}
