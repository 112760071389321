.usermodal {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, auto);
  grid-row-gap: 10px ;
  //height: max-content;
  &_row {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
  }
}