@import './../../styles/variables';

.postalcodes {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filterby {
      width: 300px;
    }
    &_buttons {
      &_load {
        background-color: $color-complementary-3 !important;
        border-radius: 6px;
        height: 40px;
        font-size: 14px !important;
        text-transform: none;
        margin-left: 10px !important;
        padding: 0 15px !important;
        &_icon {
          margin-right: 5px;
          font-size: 25px !important;
        }
      }
    }
  }
}
