@import '../../../styles/variables';

.containertype-modal {
  &_fields {
    display: flex;
    flex-direction: row;
    #MUI_name {
      width: 240px;
      margin-right: 20px;
    }
  }
  &_shipmentstypes {
    margin-top: 20px;
    display: flex;
  }
}
