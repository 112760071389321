@import '../../styles/_variables.scss';

.login {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    width: 90%;
    min-width: 600px;
    max-width: 1000px;
    height: 80%;
    overflow: hidden;
    &_form {
      flex: 1;
      min-width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px 50px;

      &_logo {
        width: 100%;
        max-width: 280px;
        margin-bottom: 20px;
      }

      &_label {
        font-size: 14px;
        font-weight: normal;
        width: 100%;
        margin: 1rem 0 0.3rem 0;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      input {
        width: 100%;
        height: 2.5rem;
        border: 1.5px solid $color-medium;
        border-radius: 5px;
        padding: 0 8px;
      }

      &_button {
        text-align: right;
        width: 100%;
        margin-top: 1rem;

        button {
          height: 40px;
          width: 120px;
          background-color: $color-primary;
          border-radius: 6px;
          border: none;
          color: $color-white;
          font-size: 11pt;
          cursor: pointer;
        }

        &-disabled {
          background-color: $color-disabled !important;
        }
      }

      &_error {
        font-size: 12px;
        color: $color-delete;
      }
    }
    &_photo {
      flex: 2;
      overflow: hidden;
      height: 100%;
      &_img {
        position: relative;
        left: -80px;
        height: 100%;
      }
    }
  }
}
