@import './../../styles/variables';
.consigmentNote {
  &_header {
    display: flex;
    flex-direction: column;
    padding: 10px;
    &_complementData {
      width: 600px;
      display: grid;
      grid-template-columns: 140px 100px 140px 100px 120px 200px;
      grid-gap: 20px;
      margin-bottom: 10px;
      align-items: flex-end;
    }
    &_down {
      margin-top: 10px;
      &-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        &_values {
          font-size: 16px;
          &_text {
            font-size: 18px;
            margin-left: 10px;
            color: $color-check;
          }
          &_status {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            color: $color-normal;
            grid-gap: 20px;
          }
        }
        &_actions {
          align-self: flex-end;
          &-files {
            margin-top: 10px;
            :nth-child(1) {
              margin-right: 10px;
            }
          }
        }
      }
      &-add {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }
}
