@import './../../styles/variables';

.toolbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  height: $header-height;
}

.toolbar-title-items-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.toolbar-session-items-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.toolbar-logo-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.toolbar-logo {
  height: 70%;
}

.toolbar-title {
  font-size: 20px;
  margin-left: 30px;
  color: white;
  border-left: 1px solid white;
  padding-left: 20px;
}

.toolbar-divider {
  border-right: 1px solid $color-medium;
  width: 1px;
  height: 40% !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.toolbar-user-icon {
  color: white;
  font-size: 40px;
}

.toolbar-user-name {
  padding: 0 5px;
  color: white;
}

.toolbar-logout-icon {
  color: white;
  background-color: transparent;
  cursor: pointer;
  margin-left: 20px;
}
