@import '../../../styles/variables';

.clientmodal {
  display: grid;
  grid-template-columns: 1fr 220px;
  grid-column-gap: 30px;

  &_info {
    &_general {
      margin-bottom: 15px;
      display: grid;
      grid-template-columns: 160px 1fr 1fr 1fr 80px;
      grid-gap: 10px;
      grid-column: 1 / 2;

      #MUI_client_number {
        grid-column: 1 / 2;
      }
      #MUI_name {
        grid-column: 2 / 6;
      }
      #MUI_rfc {
        grid-column: 1 / 2;
      }
      #MUI_business_name {
        grid-column: 2 / 6;
      }
      #MUI_contact_name {
        grid-column: 1 / 2;
      }
      #MUI_email {
        grid-column: 2 / 4;
      }
      #MUI_phone {
        grid-column: 4 / 6;
      }
      #MUI_statecity {
        grid-column: 1 / 4;
      }
      #MUI_cp {
        grid-column: 4 / 6;
      }
      #MUI_address {
        grid-column: 1 / 6;
      }
    }
    &_fiscal {
      margin-bottom: 15px;
      display: grid;
      grid-gap: 10px;
    }
  }

  &_settings {
    background-color: $color-light;
    border-radius: 10px;
    padding: 10px;
    height: max-content;
    display: grid;
    grid-row-gap: 10px;
    justify-items: flex-start;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    #MUI_active {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    #MUI_regimen {
      margin: 0;
      padding-left: 10px;
      justify-self: flex-end;
    }
    #MUI_type {
      margin: 0;
      padding-left: 10px;
      justify-self: flex-end;
    }
    #MUI_credit {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      #MUI_credit_switch {
        justify-self: flex-end;
        grid-column: 1 / 3;
        margin-bottom: 10px;
      }
      #MUI_credit_amount {
        grid-column: 1 / 2;
      }
      #MUI_credit_days {
        grid-column: 2 / 3;
      }
    }
  }

  &_buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3rem;

    button {
      width: fit-content;
      grid-row: 1 / 2;
      justify-self: end;
      //place-self: center;
      margin: 10px 0 0 10px;
    }
  }

  &_error {
    color: $color-delete;
  }
}
