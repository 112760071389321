@import './../../styles/variables';

.dashboard {
  &_date {
    align-self: center;
    width: max-content;
    padding: 10px 30px 10px 10px;
    margin-bottom: 20px;
  }

  &_container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    width: 800px;

    &_indicators {
      padding-bottom: 20px;
    }

    &_tops {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      &_table {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 2rem repeat(auto-fill, 32px);
        row-gap: 10px;

        height: fit-content;
        max-height: 400px;

        h4 {
          grid-row: 1 / 2;
          grid-column: 1 / 2;
          margin: 0;
          padding: 10px;
        }

        &-items {
          grid-column: 1 / 3;
          display: grid;
          grid-template-rows: repeat(auto-fill, 3rem);
          grid-row-gap: 5px;

          overflow: auto;
          height: 330px;

          &-item:nth-child(even) {
            background: $color-medium;
          }

          &-item:nth-child(odd) {
            background: $color-light;
          }

          &-item {
            display: grid;
            grid-template-columns: 70% 30%;

            width: auto;
            padding: 5px;

            height: 3rem;
            //border-bottom: 1px solid $color-medium;

            p {
              margin: 0;
            }

            p:nth-child(2) {
              text-align: end;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}
