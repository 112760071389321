.costedit {

  &_card {
    max-width: 600px;
    min-width: 500px;
    margin: 10px;

    &_content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 3rem;
      margin: 10px;

      &-row {
        display: grid;
        grid-template-columns: 50% 50%;

        div > div {
          place-content: center;
        }
      }

      button {
        grid-column: -2 / 1;

        //margin-right: 1000px;
      }
    }
  }
}