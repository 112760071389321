$color-primary: #80c355;
$color-secondary: #414243;
$color-complementary: #ffdd00;
$color-complementary-2: #76b06e;
$color-complementary-3: #006d7b;
$color-complementary-4: #d4d700;
$color-complementary-5: #d7a100;
$color-light: #f2f2f2;
$color-white: #ffffff;
$color-dark: #28292b;
$color-primary-light: #e5efc1;
$color-medium: #adadad;
$color-normal: #707070;
$color-disabled: #e4e4e4;
$color-delete: #fa6347;
$color-check: #07b31c;

$header-height: 60px;
$sidemenu-open-width: 200px;
$sidemenu-close-width: 70px;
