@import './../../styles/variables';

.actionMenu {
  &_button {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 12px;
    cursor: pointer;
    height: 30px;

    &_main {
      background-color: $color-primary;
      padding: 5px 5px 5px 10px;
      border-radius: 5px 0 0 5px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: $color-complementary-2;
      }
    }
    &_dropdown {
      min-width: 5px;
      background-color: $color-primary;
      border-radius: 0 5px 5px 0;
      padding-right: 2px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: $color-complementary-2;
      }
    }
  }
  &_item {
    &_icon {
      font-size: 20px !important;
    }
    &_label {
      margin-left: 5px;
      padding-top: 2px;
    }
  }
}
.saveColor {
  color: $color-primary;
}
.printColor {
  color: $color-complementary-3;
}
.deleteColor {
  color: $color-delete;
}
.closeColor {
  color: $color-dark;
}
