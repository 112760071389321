@import '../../../styles/variables';

.agreement_price_modal {
  align-self: center;
  width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 20px;
}
