@import '../../../styles/variables';

.quoteServices {
  display: flex;
  flex-direction: row;

  &_left {
    min-width: 550px;
    max-width: 550px;
    justify-self: flex-start;
    margin-right: 10px;
  }

  &_right {
    flex-grow: 1;
    min-width: 550px;
    max-width: 650px;
    height: max-content;
  }
}
