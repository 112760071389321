@import '../../../styles/variables';

.dashboard-score {
  margin-top: 40px;
  display: flex;
  align-items: center;

  &_label {
    font-size: 14px;
    margin-right: 20px;
    min-width: 120px;
    text-align: right;
  }

  &_table {
    display: grid;
    grid-template-columns: repeat(4, 120px);

    &_item {
      text-align: center;
      margin-right: 10px;
      max-width: 120px;
      border: 2px solid $color-secondary;
      border-radius: 10px;

      &_field {
        background-color: $color-light;
        color: $color-dark;
        border-radius: 10px 10px 0 0;
        font-size: 12px;
        height: 30px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_color {
        height: 10px;
      }

      &_value {
        background-color: white;
        border-radius: 0 0 10px 10px;
        color: $color-secondary;
        padding: 10px 0;
        font-size: 20px;
        font-weight: bolder;
      }
    }
  }

  &_graph {
    width: 200px;
  }
}
