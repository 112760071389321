@import './../../styles/variables';

.shipmenttypes {
  &_title {
    font-size: 12px;
    margin-bottom: 10px;
  }

  &_select {
    padding: 0 10px;
    display: grid;
    grid-auto-flow: column;
  }
}
